/* @import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;700&family=Barlow+Semi+Condensed:wght@400;700&family=Barlow:wght@300;400;500;600;800&family=Roboto+Slab:wght@800&display=swap"); */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "AIAIAIv4 normal";
  font-size: 16px !important;
  height: 100%;
  width: 100%;
}

/* body::-webkit-scrollbar {
 display: none;
}

body {
 -ms-overflow-style: none;
 scrollbar-width: none;
} */

:root {
  --light-gray: #e8e8e8;
  --gray: #ccc;
  --dark-gray: #999;
  --darker-gray: #666;
  --new-gray: #3e4755;

  --white: #fff;
  --black: #000;
  --dark: #333;
  --icon: #27262b;
  --common-dark: #322925;
  --error: #9c0004;

  /*   --primary-light: #e5ecf4;
    --primary-deep: #134866;
    --primary-bright: #146c83; */

  --primary-light: #e5ecf4;
  --primary-bright: #2E6DAD;
  --primary-deep: #164777;
  --primary-dark: #0C2F4E;

  /* --tertiary-bright: #ffa115;
  --tertiary-deep: #fa8031;
  --tertiary-dark: #e56204; */
  --tertiary-bright: #F2E1CC;
  --tertiary-deep: #F8CC96;
  --tertiary-dark: #EEA54C;

  --quaternary-bright: #2E6DAD;
  /*#5d307c;*/
  --quaternary-deep: #164777;
  /*#491c69;*/
  --quaternary-dark: #0C2F4E;
  /*#9e1d57;*/

  --bg-gray: #efefef;
  --bg-light: #f5f5f5;
  --primary: #116bac;
  --link: #116bac;
  --line: #f2f2f2;
  --border: #dee2e6;
  --background: #fff;
  --surface: #fff;
  --on-background: #545454;
  --on-background-secondary: #999;
  --on-surface: #545454;
  --on-primary: #fff;
  --on-primary-light: #545454;
  --btn-primary: #116bac;
  --btn-primary-hover: #1688da;
  --btn-secondary: #fff;
  --btn-secondary-hover: #fff;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);

  --footer-height: 100px;
  --auth-page-width: 65%;
}

/* #root {
 height: 100%;
} */

.page-container {
  position: relative;
  min-height: 100vh;
}

.content-wrap {
  padding-bottom: var(--footer-height);
}

.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  color: var(--dark);
  min-height: calc(100vh - var(--footer-height));
}

.App-mw {
  min-width: 1400px;
}

a {
  color: inherit;
}

.app-footer {
  background-color: var(--surface);
  -webkit-box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
  box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 0;
  padding: 20px 0;
  height: var(--footer-height);
}

.app-footer>.container {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.app-footer-logo {
  width: 58.33%;
}

.app-footer-social {
  width: 41.67%;
}

.app-footer .social-icons .list-inline-item {
  margin-bottom: .5rem;
}

.list-inline-item:not(:last-child) {
  padding-right: .5rem;
}

.list-inline-item {
  display: inline-block;
}

.app-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.app-row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}

.app-footer .app-row {
  padding: 1rem 0;
}

.app-footer>.container>.app-row {
  padding-bottom: 0;
  padding-top: 0;
}

.app-footer .app-footer-links {
  font-size: 14px;
}

.app-footer .app-footer-download {
  padding-top: 1rem;
}

.title-category {
  font-size: 12px;
  margin-top: 0;
  color: var(--on-background-secondary);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: .5rem;
  /* font-weight: 500; */
  line-height: 1.4;
  /* color: var(--on-background); */
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.app-footer .app-footer-links .app-footer-links-top {
  -ms-flex-align: center;
  align-items: center;
  display: flex;
  padding-bottom: .5rem;
}

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}

@media (min-width: 768px) {
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

@media (min-width: 768px) {
  .app-footer>.container>.app-row:first-child {
    padding-bottom: 1rem;
  }
}

@media (min-width: 768px) {
  .app-footer .app-footer-download {
    padding-top: 0;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}

.text-end {
  text-align: right !important;
}

@media (min-width: 768px) {
  .app-footer>.container>.app-row:first-child {
    padding-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

}

.app-row a,
.app-row a:hover {
  color: var(--link);
}

.app-row a {
  text-decoration: none;
}

.app-row a:hover {
  text-decoration: underline;
}

.app-footer-app .app-footer-links li {
  position: relative;
}

.app-footer .app-footer-links .app-footer-links-bottom {
  border-top: 2px solid #f2f2f2;
  padding-top: .5rem;
}

.app-footer ul {
  margin-bottom: 1rem;
}

.app-footer .copyright {
  color: #545454;
  font-size: 13px;
  text-decoration: none;
}

/* @media (min-width: 768px) {
.container, .container-md, .container-sm {
    max-width: 720px;
}
}
@media (min-width: 576px) {
.container, .container-sm {
    max-width: 540px;
}
} */

.app-footer .app-footer-links .app-footer-links-top .dropup .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 14px;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  flex: 1 1 auto;
}

.dropdown-toggle {
  white-space: nowrap;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.dropdown,
.dropdown-center,
.dropend,
.dropstart,
.dropup,
.dropup-center {
  position: relative;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn-secondary {
  background: var(--btn-secondary);
  border-color: var(--btn-secondary);
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  color: var(--link);
}

.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled):focus {
  background: var(--btn-secondary-hover);
  border-color: var(--btn-secondary-hover);
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, .16);
  box-shadow: 0 6px 10px rgba(0, 0, 0, .16);
  color: var(--link);
  text-decoration: none;
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 1;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  flex: 1 1 auto;
}

/* .app-footer-link-chart a, .app-footer-links-bottom a, .app-footer-link-chart a:hover, .app-footer-links-bottom a:hover {
  color: var(--link);
  text-decoration: underline;
} */
.app-footer .app-footer-links .app-footer-links-top .dropup {
  margin-left: 2rem;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-menu.show {
  display: block;
}

.btn-check:active+.btn,
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.app-footer .app-footer-links .app-footer-links-top .dropup .btn:hover,
.app-footer .app-footer-links .app-footer-links-top .dropup .btn:not(:disabled):not(.disabled):active,
.app-footer .app-footer-links .app-footer-links-top .dropup .btn:not(:disabled):not(.disabled):active:focus,
.app-footer .app-footer-links .app-footer-links-top .dropup .btn:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  z-index: 1000;
  display: block;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  position: absolute;
  inset: auto auto 0px 0px;
  transform: translate3d(0px, -37px, 0px);
}

.dropdown-menu a:hover {
  text-decoration: underline;
  background-color: #e8ecf0
}

/* COMMON */

.ov-hidden {
  overflow: hidden;
}

.ov-y-scroll {
  overflow-y: scroll;
}

.ov-auto {
  overflow: auto;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.unclickable {
  pointer-events: none;
}

.unselectable {
  user-select: none;
}

.hidden {
  visibility: hidden;
}

.hidden-plus {
  visibility: hidden;
  position: absolute;
  z-index: 3;
}

textarea {
  resize: none;
}

button {
  border: none;
}

.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75%;
}

.h-50 {
  height: 50% !important;
}

.w-50 {
  width: 50% !important;
}

.w-fit {
  width: fit-content !important;
}

.pos-relative {
  position: relative;
}

.pos-absolute {
  position: absolute;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.d-flex-important {
  display: flex !important;
}

.f-column {
  flex-direction: column;
}

.gap-8 {
  gap: 8px;
}

.f-shrink-0 {
  flex-shrink: 0;
}

.row-reverse {
  flex-direction: row-reverse;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.align-items-start {
  align-items: start;
}

.op-5 {
  opacity: 0.5;
}

.op-8 {
  opacity: 0.8;
}

/* SPACING */
/* padding */
.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-4 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-4 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 2rem;
}

.p-4 {
  padding: 3rem;
}

.ps-1 {
  padding-left: 0.5rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-3 {
  padding-left: 2rem;
}

.ps-4 {
  padding-left: 3rem;
}

.pe-1 {
  padding-right: 0.5rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-3 {
  padding-right: 2rem;
}

.pe-4 {
  padding-right: 3rem;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 2rem;
}

.pt-4 {
  padding-top: 3rem;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 2rem;
}

.pb-4 {
  padding-bottom: 3rem;
}

/* MARING */

.mx-1 {
  margin: 0 0.5rem;
}

.mx-2 {
  margin: 0 1rem;
}

.mx-3 {
  margin: 0 2rem;
}

.mx-4 {
  margin: 0 3rem;
}

.my-1 {
  margin: 0.5rem 0;
}

.my-2 {
  margin: 1rem 0;
}

.my-3 {
  margin: 2rem 0;
}

.my-4 {
  margin: 3rem 0;
}

.ms-auto {
  margin-left: auto;
}

.me-auto {
  margin-right: auto;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-3 {
  margin-left: 2rem;
}

.ms-4 {
  margin-left: 3rem;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-2 {
  margin-right: 1rem;
}

.me-3 {
  margin-right: 2rem;
}

.me-4 {
  margin-right: 3rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 3rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 2rem;
}

.mb-4 {
  margin-bottom: 3rem;
}

/* TYPOGRAPHY */

.montserrat-light {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.montserrat-normal {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

/* black?? */
@font-face {
  font-family: "AIAIAIv4 black";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-Black.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 black cond";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-BlackCondensed.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 black ext";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-BlackExtended.ttf") format("truetype");
}

/* light */
@font-face {
  font-family: "AIAIAIv4 light";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-Light.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 light ext";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-LightExtended.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 light cond";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-LightCondensed.ttf") format("truetype");
}

/* medium */
@font-face {
  font-family: "AIAIAIv4 medium";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 medium ext";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-MediumExtended.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 medium cond";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-MediumCondensed.ttf") format("truetype");
}

/* bold */
@font-face {
  font-family: "AIAIAIv4 bold";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 bold ext";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-BoldExtended.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 bold cond";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-BoldCondensed.ttf") format("truetype");
}

/* normal */
@font-face {
  font-family: "AIAIAIv4 normal";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-Normal.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 normal ext";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-NormalExtended.ttf") format("truetype");
}

@font-face {
  font-family: "AIAIAIv4 normal cond";
  /*Can be any text*/
  unicode-range: U+000-5FF;
  font-display: swap;
  src: local("AIAIAI"),
    url("./assets/fonts/AIAIAIv4-NormalCondensed.ttf") format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

.ff-normal {
  font-family: "AIAIAIv4 normal";
}

.ff-normal-cond {
  font-family: "AIAIAIv4 normal cond";
}

.ff-normal-ext {
  font-family: "AIAIAIv4 normal ext";
}

.ff-black {
  font-family: "AIAIAIv4 black";
}

.ff-black-cond {
  font-family: "AIAIAIv4 black cond";
}

.ff-black-ext {
  font-family: "AIAIAIv4 black ext";
}

.ff-light {
  font-family: "AIAIAIv4 light";
}

.ff-light-cond {
  font-family: "AIAIAIv4 light cond";
}

.ff-light-ext {
  font-family: "AIAIAIv4 light ext";
}

.ff-medium {
  font-family: "AIAIAIv4 medium";
}

.ff-medium-cond {
  font-family: "AIAIAIv4 medium cond";
}

.ff-medium-ext {
  font-family: "AIAIAIv4 medium ext";
}

.ff-bold {
  font-family: "AIAIAIv4 bold";
}

.ff-bold-cond {
  font-family: "AIAIAIv4 bold cond";
}

.ff-bold-ext {
  font-family: "AIAIAIv4 bold ext";
}

/* --- */
.text-dark {
  color: var(--dark);
}

.text-common-dark {
  color: var(--common-dark);
}

.text-darker-gray {
  color: var(--darker-gray);
}

.text-dark-gray {
  color: var(--dark-gray);
}

.text-new-gray {
  color: var(--new-gray);
}

.text-gray {
  color: var(--dark-gray);
}

.text-quat-de {
  color: var(--quaternary-deep);
}

.text-error {
  color: var(--error);
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.underline {
  text-decoration: underline;
}

.no-decoration {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.no-wrap {
  white-space: nowrap;
}

.break-word {
  overflow-wrap: break-word;
  text-wrap: balance;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.lh-90 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1;
}

.lh-2 {
  line-height: 2;
}

.lh-3 {
  line-height: 3;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 8px;
}

/* INPUT GLOBAL */
input[type="radio"] {
  height: 18px;
  width: 18px;
  cursor: pointer;
  pointer-events: none;
  display: grid;
  place-content: center;
  border-width: 1.5px;
  border-color: #666;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="radio"]:focus {
 border-color: #666;
}

input[type="radio"]::before {
 content: "";
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: scale(0);
 transition: 120ms transform ease-in-out;
 box-shadow: inset 1em 1em #666;
}

input[type="radio"]:checked::before {
 transform: scale(1);
} */

input:focus-visible {
  outline: none;
}

select:focus-visible {
  outline: none;
}

/* BG */
.bg-white {
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.bg-darker-gray {
  background-color: var(--darker-gray);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.bg-gray {
  background-color: var(--bg-gray);
}

.bg-prim-de {
  background-color: var(--primary-deep);
}

.bg-quat-br {
  background-color: var(--quaternary-bright);
}

.bg-quat-de {
  background-color: var(--quaternary-deep);
}

.bg-tert-br {
  background-color: var(--tertiary-bright);
}

.bg-tert-de {
  background-color: var(--tertiary-deep);
}

.bg-transp {
  background-color: transparent;
}

/* COLOR */
.text-white {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

/* BORDER */
.br-8 {
  border-radius: 8px;
}

.br-6 {
  border-radius: 6px;
}

.outlined-button {
  border: 2px solid var(--quaternary-deep) !important;
}

.b-quat-de {
  border: 1px solid var(--quaternary-deep);
}

.b-prim-de {
  border: 1px solid var(--primary-deep);
}

.b-none {
  border: none;
}

.d-none {
  display: none;
}