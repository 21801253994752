/*  HEADER */
.header-login {
    width: clamp(700px, var(--auth-page-width), 90%) !important;
    justify-self: center;
    padding: 0 !important;
}

.main-header {
    height: 82px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    padding: 0 1rem;
}

.maintenance-banner {
    padding: 1rem;
    margin-top: 1rem;
    background-color: #f7ce66;
    color: var(--quaternary-dark);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-exact {
    width: 170px;
    height: 58px;
    flex-grow: 0;
    margin-right: 1.5rem;
}

.logo-fao {
    width: 235px;
    height: 58px;
    flex-grow: 0;
}

.btn-header-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 9px;
    font-size: 10px;
    line-height: 10px;
    white-space: nowrap;
}

.btn-header {
    color: var(--primary-deep);
    padding: 4px 8px 4px 4px;
    background-color: transparent;
    /* border: 1px solid rgba(19, 72, 102, 0.2); primary-deep */
    height: 32px;
    display: flex;
    align-items: center;
}

.btn-header:disabled {
    color: var(--dark-gray);
    /* border: 1px solid var(--light-gray); */
}

.btn-header.highlighted {
    border-left: 4px solid rgba(19, 72, 102, 0.2);
}

.btn-switch-project {
    color: var(--white);
    padding: 4px 8px 4px 12px;
    background-color: var(--quaternary-deep);
    border: 1px solid var(--primary-deep);
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

/* LOGIN */
.login-container {
    display: flex;
    flex-direction: column;
    width: clamp(700px, var(--auth-page-width), 90%);
    align-self: center;
    margin-bottom: 1rem;
}

.login-image-container {
    width: 100%;
    aspect-ratio: 700 / 383;
    flex-grow: 0;
    margin: 0 !important;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    padding-bottom: 1rem
}

.reset-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
    padding-bottom: 1rem
}

.login-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.login-description {
    font-size: 11px;
    line-height: 16px;
    color: var(--dark);
    font-weight: 400;
}

.form-row {
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    position: relative;
    align-self: center;
}

.form-row-border {
    padding-left: 0.5rem;
    border-left: 8px solid;
}

.form-row-border.tertiary {
    border-color: var(--tertiary-dark);
}

.form-row-border.primary {
    border-color: var(--primary-bright);
}

.form-row-border.quaternary {
    border-color: var(--quaternary-dark);
}

.form-row-icon {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.form-row-skeleton {
    height: 36px;
    width: 100%;
    flex-grow: 1;
}

.form-group {
    width: 100%;
    height: 36px;
    padding: 5px 8px;
    border: 1px solid var(--gray);
    border-radius: 6px;
    background-color: var(--white);
    color: var(--gray);
    font-weight: 500;
    position: relative;
}

.form-group.disabled {
    background: #eeeeee !important;
    border: 1.5px solid #999999;
}

.form-group.error {
    border: 1px solid var(--error);
}

.form-group.focused {
    border: 1px solid var(--dark-gray);
}

.form-group-label {
    position: absolute;
    left: 8px;
    top: 9px;
    font-size: 11px;
    color: var(--dark);
    transition: top 0.2s ease-out;
}

.form-group-label.small {
    top: 3px;
    font-size: 10px;
    font-weight: 400;
    color: var(--dark-gray);
}

.error {
    color: var(--error) !important;
}

.input-login {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
}

.select-icon {
    position: absolute;
    right: 3px;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-icon {
    position: absolute;
    top: 4px;
    right: 10px;
}

.input-percentage {
    position: absolute;
    top: 12px;
    right: 10px;
}

.toggle-label {
    min-width: 61px;
    padding-right: 1rem;
    font-size: 11px;
    color: var(--dark-gray);
    font-weight: 500;
}

.btn-login {
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    grid-template-areas: "text icon";
    grid-template-columns: 1fr 36px;
    height: 36px;
    border: none;
    min-width: 100px;
}

.btn-login .label {
    grid-area: text;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 500;
    color: var(--white);
    line-height: 2;
    padding: 0.5rem 1rem;
}

.btn-login .icon {
    grid-area: icon;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgot-password {
    display: flex;
    align-items: center;
    color: var(--darker-gray);
}

/* select project */
.welcome-user-header {
    font-size: 13px;
    color: #000;
    padding-bottom: 8px;
    border-bottom: 6px solid var(--tertiary-bright);
    width: fit-content;
}

.form-group-v2 {
    width: 100%;
    height: 36px;
    padding: 5px 8px;
    border: 1px solid var(--darker-gray);
    border-radius: 6px;
    background-color: var(--white);
    color: var(--dark);
    position: relative;
}

.select-login {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    font-size: 13px;
}

.btn-new-project {
    width: fit-content;
    border: 1px solid var(--quaternary-deep);
    border-radius: 8px;
    overflow: hidden;
    height: 36px;
}

.select-project-p {
    word-break: normal;
    display: flex;
    gap: .2rem;
    flex-direction: column;
}

/* select project table */
.select-project-table {
    border-collapse: collapse;
    border-spacing: 0;
    color: var(--common-dark);
    width: 100% !important;
    table-layout: fixed;
}

.select-project-table thead tr {
    background: #57534b;
    height: 34px;
    text-align: left;
}

.select-project-table th {
    padding: 6px 10px;
    vertical-align: top;
    line-height: 11px;
}

.select-project-table tbody tr {
    background-color: #fff;
    line-height: 11px;
    text-align: left;
    border-bottom: 2px solid #f4f3ee;
}

.select-project-table tbody tr .add-button {
    position: absolute;
    left: 102%;
    top: 6px;
}

.select-project-table .cell-content {
    height: 36px !important;
    /* display: block; */
    display: flex;
    align-items: center;
}

/*.select-project-table .cell-content-project span {
    padding: 6px 10px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

 .select-project-table .cell-content-project:hover {
    white-space: normal;
    word-break: keep-all;
    text-overflow: clip;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 2;
}*/

.select-project-table .cell-content .span {
    padding: 6px 10px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-project-table .cell-content .form-row-skeleton {
    padding: 6px 10px;
}

.select-project-table .cell-content .span:hover {
    white-space: normal;
    word-break: keep-all;
    text-overflow: clip;
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 2;
}

.w-210 {
    width: 210px !important;
}

/* Project Builder */
.builder-container {
    display: grid;
    grid-template-columns: 58px 1fr 0px 768px;
    grid-template-rows: 60px 36px 1fr;
    grid-template-areas:
        "side top top top"
        "side maintabs actionstabs actionstabs"
        "side main main actions";
    height: 100%;
    width: 100%;

    flex-grow: 1;
    transition: grid ease-in 0.25s;
}

.edit-activity-container {
    display: grid;
    grid-template-columns: 58px 1fr 0px 768px;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        "side top top top"
        "side main main actions";
    height: 100%;
    width: 100%;

    flex-grow: 1;
    transition: grid ease-in 0.25s;
}

/* .builder-container.terms {
 grid-template-columns: 58px 1fr 0px 554px;
} */

.builder-container.actions-small {
    grid-template-columns: 58px 1fr 175px 570px;
}

.builder-container.actions-close {
    grid-template-columns: 58px 1fr 330px 14px;
}

.side {
    grid-area: side;
    background-color: var(--primary-deep);
    z-index: 1000;
    overflow: hidden;
}

.builder-top {
    grid-area: top;
    margin-right: 22px;
    border-top: 1px solid var(--primary-light);
    display: grid;
    grid-template-columns: minmax(0, 1fr) 260px;
    justify-content: space-between;
}

.builder-main {
    grid-area: main;
    display: grid;
    grid-template-columns: 1fr 0px;
}

.builder-main>section {
    max-width: 960px;
}

.builder-main.tabs-closed {
    grid-template-columns: minmax(0, 1fr) 550px;
    min-width: 573px;
}

/* Topbar */
.steps-container {
    padding: 0.5rem 0 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    color: #655f5c;
    overflow-x: scroll;
}

.steps-container::-webkit-scrollbar {
    height: 4px;
    width: 0px;
}

.steps-labels {
    max-height: 24px;
    display: flex;
    align-items: center;
}

.step-number {
    height: 24px;
    width: 24px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;
    border: 2px solid #655f5c;
    border-radius: 50%;
    margin-right: 0.5rem;

    transition: all 0.2s ease-out;
}

.step-number-active {
    color: #fff !important;
    border: 1px solid var(--quaternary-deep);
    background-color: var(--quaternary-deep);
}

.step-number-done,
.step-number-touched {
    margin-right: 1.5rem;
}

.step-number-done::after,
.step-number-touched::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -16px;
    height: 14px;
    width: 14px;
}

.step-number-done::after {
    background: url("./assets/icons/check.svg");
    background-repeat: no-repeat;
}

.step-number-touched::after {
    background: url("./assets/icons/dots.svg");
    background-repeat: no-repeat;
}

.project-label {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
}

.project-label:hover {
    word-break: keep-all;
    text-overflow: clip;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    z-index: 2;
    padding: 0.5rem;
    max-width: none;
}

.step-label {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 1rem;
}

/* Main Tabs */
.builder-main-tabs {
    grid-area: maintabs;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid var(--common-dark);
    position: relative;
    padding-right: 5rem;
}

.bmt-container {
    display: flex;
    position: relative;
    height: 32px;
    width: fit-content;
    margin-left: 90px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: var(--common-dark);
    border-radius: 6px 6px 0 0;
}

/* trick to hide the border bottom */
.bmt-container::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 1px;
    background-color: white;
}

.bmt-toggle-t2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    background-color: #dda551;
    cursor: pointer;
}

.bmt-t2 {
    display: flex;
    align-items: center;
    background-color: #ffb950;
    border-radius: 0px 6px 0 0;
}

.bmt-switch-container {
    height: 12px;
    width: 21px;
    background: #ffb950;
    border: 1px solid #81581e;
    border-radius: 16px;
    display: flex;
    align-items: center;
    /* box-sizing: border-box; */
    padding: 0 2px;
    cursor: pointer;
    transition: all 0.3s;
}

.bmt-switch-handle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: #81581e;
    overflow: hidden;
}

/* Builder Actions Tabs */
.builder-actions-tabs {
    grid-area: actionstabs;
    position: relative;
    display: flex;
    align-items: flex-end;
}

.action-tab {
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px 6px 10px;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: var(--common-dark);
    border-radius: 6px 6px 0px 0px;
}

.action-tab-closed {
    padding: 8px 16px 6px;
}

/* builder actions tabs = bat */
.bat-btn-hide {
    position: absolute;
    top: 6px;
    right: 100%;
    z-index: 2;

    margin-right: 6px;
    display: flex;
    align-items: center;
    color: var(--common-dark);
    opacity: 0.5;
}

.bat-btn-hide span {
    font-size: 9px;
    max-width: 26px;
    overflow-wrap: break-word;
}

/* Builder Actions */
.builder-actions {
    grid-area: actions;
    border: 1px solid var(--primary-light);
    overflow: hidden;
}

.actions-close .builder-actions {
    background-color: var(--bg-gray);
}

/* Live Updates Action */
.live-updates {
    /* d-flex f-column w-100 h-100 p-2 bg-gray */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: var(--bg-gray);
    border-left: 2px dashed #ccc9c8;
}

.live-updates-accordion-header {
    /* d-flex align-items-center clickable fs-10 ps-3 rel */
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    position: relative;
    z-index: 1;
    height: 36px;
    color: rgba(49, 42, 29, 0.75);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0.5rem;
}

.live-updates-accordion-header span:first-of-type {
    position: absolute;
    height: 36px;
    top: 0;
    left: 8px;
    display: flex;
    align-items: center;
}

.description-container {
    width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(4, min-content);
    grid-template-rows: 1fr;
    overflow-x: scroll;
}

.description-container span {
    white-space: nowrap;
}

.live-updates-dc {
    border-top: 4px solid rgba(50, 41, 37, 0.5);
    background-color: var(--white);
}

.project-results-dc span {
    color: rgba(50, 41, 37, 0.75);
}

.btn-expand-table {
    height: 24px;
    width: 59px;
}

.compare-button {
    padding: 8px 16px;
    border-radius: 6px;
    background-color: var(--primary-bright);
    color: var(--white);
    font-weight: 500;
}

.compare-button:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* .live-updates-tables-container {

} */
.live-updates-table {
    color: var(--common-dark);
    border-collapse: collapse;
    border-spacing: 2px 0;
}

.live-updates-table thead tr {
    height: 24px;
    text-align: left;
}

.live-updates-table th {
    padding: 0;
    background: #6a655d;
    font-size: 10px;
    color: var(--white);
}

.live-updates-table th:first-child {
    min-width: 190px;
}

.th-name {
    padding: 6px 16px;
    font-size: 12px;
    background: #6a655d;
}

.th-area {
    padding: 6px 8px;
    background: #57534b;
    font-size: 11px;
}

.th-emissions {
    padding: 6px 8px;
    background: #146c83;
    font-size: 11px;
}

.live-updates-table tbody tr {
    background-color: #fff;
    text-align: left;
    border-bottom: 2px solid #f4f3ee;
}

.live-updates-table tbody tr:last-child {
    border-bottom: 2px solid #908c8a;
}

.live-updates-table tbody tr td {
    padding: 6px 10px;
    font-size: 11px;
    line-height: 11px;
}

/* .live-updates-table .cell-content {
 height: 24px !important;
 display: block;
}

.live-updates-table .cell-content span {
 width: 70px;
 padding: 6px 10px;
 position: relative;
 display: inline-block;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.live-updates-table .cell-content span:hover {
 white-space: normal;
 word-break: keep-all;
 text-overflow: clip;
 position: absolute;
 background-color: #fff;
 box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 border-radius: 5px;
 z-index: 2;
} */

/* MODAL */
.backdrop {
    position: fixed;
    overflow-y: scroll;
    inset: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: #66666666;
    z-index: 100;
}

.backdrop-transparent {
    background: transparent !important;
}

/* invece di usare media query usi clamp 
prova ad impostare 700px, se è troppo piccolo rimane al 90%
o se lo schermo è troppo grosso arriva al 50% */

.modal {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 16px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
    overflow: hidden;

    background: var(--bg-light);
    /* padding-bottom: 70px;  */
    z-index: 5;
}

.modal-sm {
    width: clamp(20%, 200px, 90%);
}

.modal-md {
    width: clamp(30%, 500px, 90%);
}

.modal-lg {
    width: clamp(60%, 900px, 90%);
}

.modal-xlg {
    width: clamp(65%, 843px, 90%);
}

.right-side-modal {
    display: flex;
    flex-direction: column;
    height: 97%;
    width: 472px;
    filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.5));
    overflow: hidden;
    background: var(--white);
    padding: 12px;
    margin-left: auto;
    margin-right: 1rem;
    overflow-y: scroll;
}

.card-overlay {
    background: rgba(0, 0, 0, 0.5);
}

/* TOOLTIPS */
.error-tooltip {
    padding: 8px;
    background: #eeeeee;
    border: 1px solid var(--error);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-size: 9px;
    line-height: 9px;
    overflow: hidden;
    max-width: 90px;
}

.tiertwo-tooltip {
    background: #ffbd15;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 1rem;
    min-height: 40px;
    margin-left: 8px;
}

.map-guide-tooltip-content {
    max-width: 700px;
    background: var(--white);
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid var(--gray);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.submodule-input-group {
    display: grid;
    grid-row-gap: 1rem;
}

.module-group {
    margin-left: 90px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.module-header {
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-bottom: 6px solid var(--tertiary-bright);
    padding-bottom: 6px;
}

.module-number {
    position: absolute;
    right: calc(100% + 1rem);
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
}

.module-number.header-number {
    align-items: flex-start;
}

.filter-activities-header {
    border: 1px solid rgba(50, 41, 37, 0.4);
    border-radius: 8px;
    padding: 0 1rem;
    width: fit-content;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.submodule-header {
    border-bottom: 1px solid rgba(50, 41, 37, 0.4);
    width: fit-content;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.submodule-header.completed {
    border-bottom: 1px solid rgba(50, 41, 37, 0.4);
    background: #eee;
}

.submodule-header-arrow {
    position: absolute;
    right: 100%;
    top: 0;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.definitions-questionmark {
    position: absolute;
    left: 100%;
    top: 0;
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone-container {
    border: none;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0.2rem;
}

.dropzone-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #F9BD24;
    stroke-width: 1.5;
    stroke-dasharray: 8 4;
    stroke-linecap: round;
    pointer-events: none;
}

.dropzone-border.animated {
    animation: dashedBorder 1s linear infinite;
}

.file-list li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    font-size: 14px;
    align-items: center;
    margin-top: 0.5rem;
}

.file-list li span {
    border-radius: 8px;
    background-color: var(--primary-light);
    padding: 0.5rem 1rem;
}

.file-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: var(--primary-light);
    padding: 0.5rem;
    gap: 1rem;
    border: 1px solid #666;
}

.notes-action-container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-gray);
    padding: 1rem 22px 1rem 1rem;
    min-height: calc(100vh - 170px);
    height: 100%;
    overflow-y: scroll;
}

.notes-action-container .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    width: fit-content;
    padding-left: 0;
}

.notes-action-container .ql-container.ql-snow {
    border: none !important;
}

.notes-action-container .ql-editor {
    padding-left: 0;
    font-family: "AIAIAIv4 normal";
}

.comments-action-container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-gray);
    overflow: visible
        /* padding: 1rem 22px 1rem 1rem; */
}

.comments-action-container .ql-toolbar.ql-snow {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    width: fit-content;
    padding-left: 0;
}

.comments-action-container .ql-container.ql-snow {
    border: none !important;
}

.comments-action-container .ql-editor {
    padding-left: 0;
    font-family: "AIAIAIv4 normal";
}

.standard-action-button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(50, 41, 37, 0.5);
    border-radius: 6px;
    height: 36px;
    padding-right: 1rem;
    padding-left: 0.5rem;
}

.note-text-editor-container {
    background-color: var(--white);
    border-radius: 8px;
    width: 100%;
    padding: 0.5rem 1rem;
}

.note-text-view-container {
    border-radius: 8px;
    width: 100%;
    padding: 1rem 2rem;
}


.comment-block {
    border-top: 1px solid var(--gray);
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
}

.hectares-text {
    color: var(--black);
    border-bottom: 1px solid rgba(50, 41, 37, 0.4);
}

.btn-add-accordion {
    width: fit-content;
    border: 1px solid var(--primary-deep);
    border-radius: 8px;
    overflow: hidden;
    height: 36px;
}

.btn-register {
    width: fit-content;
    border: 1px solid var(--darker-gray);
    border-radius: 8px;
    overflow: hidden;
    height: 36px;
}

.btn-save {
    border-radius: 8px;
    overflow: hidden;
    display: grid;
    grid-template-areas: "icon text";
    grid-template-columns: 36px 1fr;
    height: 36px;
    width: 100px;
    border: none;
}

.btn-save .label {
    grid-area: text;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 11px;
    padding: 0 1rem;
    /* border-radius: 0 8px 8px 0; */
    font-weight: 500;
    line-height: 2;
}

.btn-save .save-icon {
    grid-area: icon;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.module-group-footer {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-top: 4px solid rgba(50, 41, 37, 0.4);
}

.tiertwo-block {
    margin: 1rem 0;
    background: #efefef;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem 0.5rem 0;
    max-width: 500px;
}

.tiertwo-block button:focus {
    outline: none;
}

.tiertwo-row {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr 304px;
    gap: 1rem;
    align-items: center;
    height: 75px;
    border-radius: 8px;
    border: 1.5px solid transparent;
}

.tiertwo-row-hover:hover {
    background: rgba(255, 161, 21, 0.1);
    border: 1.5px solid var(--tertiary-bright);
}

.tiertwo-input-changed {
    background: rgba(255, 161, 21, 0.1);
    border: 1.5px solid var(--tertiary-bright);
}

.default-label {
    position: absolute;
    bottom: calc(100% + 3px);
    left: 0px;
}

.has-changed-dot {
    position: absolute;
    top: 0;
    left: -4px;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    background-color: var(--tertiary-bright);
    opacity: 1;
    animation: fadeinphoto infinite 2s;
}

.bt-1-common-dark {
    border-top: 1px solid var(--common-dark);
}

.trans-none {
    transform: none;
}

.trans-y-7 {
    transform: translateY(7px);
}

.react-datepicker-wrapper {
    height: 100%;
    width: 100%;
}

.activity-cards-container {
    margin-left: 90px;
    padding-right: 40px;
    padding-top: 1rem;
    margin-bottom: 2rem;
}

/* REPLACED BY MASONRY */
/* .activity-cards-grid {
 display: grid;
 grid-template-columns: 1fr 1fr;
 grid-template-rows: repeat(auto-fill, minmax(108px, 1fr));
 gap: 1rem;
} */

.card-container {
    position: relative;
    z-index: 1;
}

.card-container:has(.kebab-menu) {
    /* When the kebab menu is present, raise this container above others */
    z-index: 2;
}

.activity-card {
    border-radius: 8px;
    border: 1px solid #666;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
}

/* .activity-card:hover {
 border: 2px solid var(--quaternary-deep);
 margin: -1px;
} */

.activity-card .content {
    display: grid;
    grid-template-columns: 1fr 30px;
    grid-template-rows: auto auto;
    padding: 0.5rem;
    gap: 0.5rem;
}

.activity-card ul {
    list-style-type: square;
    list-style-position: inside;
    margin-left: 0.2rem;
}

.activity-card .footer {
    height: 38px;
    width: 100%;
    display: flex;
    border-top: 8px solid;
    overflow: hidden;
    background-color: var(--quaternary-deep);
    color: var(--white);
    border-radius: 0 0 6px 6px;
}

.activity-card .footer.create {
    background-color: rgba(50, 41, 37, 0.1);
    color: inherit;
}

.activity-card .footer div {
    height: 100%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    background-color: var(--quaternary-bright);
}

.kebab-menu {
    position: absolute;
    top: 30px;
    width: 139px;
    z-index: 10;
    border: 1px solid var(--bg-light);

    display: grid;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    overflow: hidden;
}

.kebab-menu-line {
    background-color: var(--white);
    display: flex;
    align-items: center;
    height: 32px;
    border-top: 1px solid var(--bg-light);
}

.kebab-menu-line:first-child {
    border-top: none;
}

.kebab-menu-line .icon {
    padding: 0 10px;
    height: 100%;
    display: flex;
    align-items: center;
}

/* results */
.co2-blocks-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fill, minmax(80px, 1fr));
    max-width: 450px;
    gap: 1rem;
}

.liveup-co2-blocks-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(auto-fill, minmax(80px, 1fr));
    max-width: 677px;
    /* padding-left: 2rem; */
    gap: 1rem;
}

/* header's buttons drop menu */
.drop-menu {
    position: absolute;
    width: 150px;
    top: 35px;
    right: 0;
    z-index: 3;

    display: grid;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
    overflow: hidden;

    padding-top: 12px;
    padding-bottom: 12px;
    background-color: var(--white)
}

.drop-menu-line {
    background-color: var(--white);
    display: flex;
    align-items: center;
    height: 32px;
}

.module-card {
    width: clamp(200px, 100%, 470px);
    min-height: 75px;
    padding: 12px 12px 12px 8px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 2px solid var(--quaternary-deep);
    background: var(--white);
    display: flex;
    gap: 8px;
}

/* confirm navigation */
.confirm-nav header {
    background-color: #57534b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    height: fit-content;
    padding: 1rem 0;
}

.confirm-nav article {
    background-color: var(--white);
    color: var(--common-dark);
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.confirm-nav footer {
    height: 70px;
}

/* loading spinner */
.loader {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    position: relative;
    animation: rotate 1s linear infinite;
}

.loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 2px solid #fff;
    animation: prixClipFix 2s linear infinite;
}

.loader.black::before {
    border-color: var(--black);
}

.result-slider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray);
    border-radius: 8px;
    padding: 4px;
    margin-top: 12px;
    position: relative;
}


.text-headline-secondary {
    font-size: 17px;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}

/* Animation Keyframes*/
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeinphoto {
    25% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }
}

@keyframes dashedBorder {
    to {
        stroke-dashoffset: -24;
    }
}